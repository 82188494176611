import { computed, ComputedRef } from "vue";
import { defineStore } from "pinia";
import { createResource } from "frappe-ui";

const URI_USER_PARAM = "helpdesk.api.auth.get_user_param";

export const useParamStore = defineStore("param", () => {
  const userParam = createResource({
    url: URI_USER_PARAM,
  });
  const init = userParam.fetch;
  const reloadUser = userParam.reload;

  const user__ = computed(() => userParam.data || {});
  const isAdmin: ComputedRef<boolean> = computed(() => user__.value.is_admin);
  const isHod: ComputedRef<boolean> = computed(() => user__.value.is_hod);

  return {
    isAdmin,
    init,
    isHod,
    reloadUser,
  };
});
